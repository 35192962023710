import request from '@/utils/request.js'

// export function pageInit(){
//     return request({
//         method:'get',
//         url:'/api/admin/product/pageInit'
//     });
// }

// export function listPage(params) {
//     return request({
//         method:'get',
//         url:'/api/admin/product/listPageAll',
//         params
//     })
// }

// // 删除
// // export function del(id){
// //     return request({
// //         url:`/api/admin/product/${id}`,
// //         method:'delete'
// //     });
// // }


// //新增初始化
// export function addInit() {
//     return request({
//         url:'/api/admin/product/addInit',
//         method:'get',
//     });
// }

// // 新增
// export function product(data) {
//     return request({
//         url:'/api/admin/product',
//         method:'post',
//         data
//     });
// }

// // 编辑初始化
// export function updateInit(id) {
//     return request({
//         method:'get',
//         url:`/api/admin/product/updateInit/${id}`
//     });
// }

// //编辑
// export function update(data) {
//     return request({
//         method:'put',
//         url:'/api/admin/product',
//         data
//     });
// }

// //详情
// export function detail(id) {
//     return request({
//         method:'get',
//         url:`/api/admin/product/${id}`
//     });
// }

// // 禁用
// export function forbidden(id) {
//     return request({
//         method:'delete',
//         url:`/api/admin/product/${id}`
//     })
// }

export function listPage(params) {
    return request({
        url:'/api/localProduct/listPage',
        method:'get',
        params
    });
}

export function pageInit() {
    return request({
        url:'/api/localProduct/pageInit',
        method:'get',
    });
}

export function delGoods(id) {
    return request({
        url:`/api/localProduct/${id}`,
        method:'delete',
    });
}

export function addInit() {
    return request({
        url:`/api/localProduct/addInit`,
        method:'get',
    });
}

export function localProduct(data) {
    return request({
        url:`/api/localProduct`,
        method:'post',
        data
    });
}


export function updateInit(id) {
    return request({
        url:`/api/localProduct/updateInit/${id}`,
        method:'get',
    });
}


export function update(data) {
    return request({
        url:`/api/localProduct`,
        method:'put',
        data
    });
}

export function stockListPage(params) {
    return request({
        method:'get',
        url:'/api/amazon/asin/stockListPage',
        params
    })
}

// 查询商品初始化
export function stockPageInit() {
    return request({
        method:'get',
        url:'/api/amazon/asin/pageInit',
    });
}

export function listExistMatchAsin(params) {
    return request({
        method:'get',
        url:'/api/localProduct/listExistMatchAsin',
        params
    });
}

export function matchingAsin(data){
    return request({
        url:'/api/localProduct/matchingAsin',
        method:'post',
        data
    });
}


export function listExistMatchAsinByProductId(params){
    return request({
        url:'/api/localProduct/listExistMatchAsinByProductId',
        method:'get',
        params
    });
}

export function deleteMatching(id) {
    return request({
        url:`/api/localProduct/deleteMatching/${id}`,
        method:'delete'
    });
}

export function second() {
    return request({
        url:'/api/localProduct/downloadExcel',
        method:'get',
    });
}





