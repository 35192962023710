import request from '@/utils/request'

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/AsinShipment/listPage',
        params
    })
}

// 查询初始化
export function pageInits() {
    return request({
        method:'get',
        url:'/api/AsinShipment/pageInit',
    });
}

//详情
export function detail(id) {
    return request({
        url:`/api/AsinShipment/${id}`,
        method:'get'
    });
}

