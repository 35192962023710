import request from '@/utils/request'
export function createInboundShipmentPlan(data) {
    return request({
        method: 'post',
        url: '/api/AsinShipment/createInboundShipmentPlan',
        data,
    });
}


export function addProduct(params) {
    return request({
        url:'/api/amazon/asin/addProduct',
        method:'get',
        params
        
    });
  }

  export function createPlanInit(data) {
    return request({
        url:'/api/AsinShipment/createPlanInit',
        method:'get',
        data
        
    });
  }

  export function addShipmentFile(data) {
    return request({
        method: 'post',
        url: '/api/AsinShipment/addShipmentFile',
        data,
    });
}
//确认申报
export function createInboundShipment(data) {
    return request({
        method: 'post',
        url: '/api/AsinShipment/createInboundShipment',
        data,
    });
}
//编辑
export function edit(id) {
    return request({
        url:`/api/AsinShipment/updateInboundShipmentInit/${id}`,
        method:'get'
    });
}
//编辑修改确认
export function updateInboundShipment(data) {
    return request({
      url: "/api/AsinShipment/updateInboundShipment",
      data,
      method: "put"
    })
  }